
import CyanPageWrapper, {
  router,
  store,
  defineComponent
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import UserData from "@/components/UserData.vue";
import {
  IonButton,
  IonInput,
  IonIcon,
  IonPage,
  IonSpinner,
  toastController,
  alertController
} from "@ionic/vue";
import {
  personAdd,
  warningOutline,
  checkmarkCircleOutline
} from "ionicons/icons";
import ValidatorSet, {
  validateDUI,
  validatePhone,
  validateEmail
} from "@/modules/cyanValidator";
import buttonTrigger from "@/modules/cyanButtonTrigger";
import unreact from "@/modules/unreact";

export default defineComponent({
  name: "Voters",
  components: {
    IonButton,
    IonInput,
    IonIcon,
    IonSpinner,
    IonPage,
    UserData,
    CyanPageWrapper
  },
  setup() {
    return {
      personAdd,
      warningOutline,
      checkmarkCircleOutline
    };
  },
  data() {
    return {
      dui: "",
      email: "",
      telefono: "",
      telefono2: "",
      telefono3: "",
      validator: null as any,
      datosPreflight: {}
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validateDUI,
        element: ".__voters-DUI",
        nextElement: ".__voters-telefono"
      },
      {
        validator: validatePhone,
        element: ".__voters-telefono",
        nextElement: ".__voters-telefono2",
        fieldName: "Teléfono de contacto"
      },
      {
        validator: validatePhone,
        element: ".__voters-telefono2",
        optional: true,
        nextElement: ".__voters-telefono3",
        fieldName: "Teléfono 2"
      },
      {
        validator: validatePhone,
        element: ".__voters-telefono3",
        optional: true,
        nextElement: ".__voters-email",
        fieldName: "Teléfono 3"
      },
      {
        validator: validateEmail,
        element: ".__voters-email",
        nextElement: ".__voters-next",
        optional: true
      }
    ]);

    buttonTrigger(".__voters-next", this.storeVotante);
  },
  updated() {
    if (this.validator) this.validator.bind();
    buttonTrigger(".__voters-next", this.storeVotante);
  },

  computed: {
    listaVotantes() {
      return store.getters.listaVotantes;
    },
    estadoPreflight() {
      const p = this.datosPreflight as any;
      if (typeof p.ok === "undefined") return "none";
      if (p.running) return "running";
      return p.ok ? "ok" : "error";
    },
    votersButtonLabel() {
      return (
        "LISTA DE VOTANTES REGISTRADOS" +
        (store.getters.listaVotantes.length
          ? " (" + store.getters.listaVotantes.length + ")"
          : "")
      );
    },

    router() {
      return router;
    },

    iconoPreflight() {
      if(this.estadoPreflight === 'ok') return checkmarkCircleOutline;
      if((this.datosPreflight as any).checkVerde) return checkmarkCircleOutline;
      return warningOutline;
    },
    estadoPreflightIcono() {
      if((this.datosPreflight as any).checkVerde && this.estadoPreflight === 'error') {
        return 'ok';
      }
      return (this as any).estadoPreflight || 'none';
    }
  },
  methods: {
    async preflightVotante() {
      const d = this as any;
      // esperar para que la máscara haga lo suyo
      await new Promise(function(resolve) {
        setTimeout(resolve, 50);
      }).then(function() {
        if (!d.dui.match("^[0-9]{8}-[0-9]$")) {
          d.datosPreflight = {};
          return;
        }

        if (d.dui == d.datosPreflight.dui) return;

        const myDui = unreact(d.dui);

        d.datosPreflight = { ok: false, running: true, dui: myDui };

        cyanRequest("addVoter", {
          isPost: true,
          needsAuth: true,
          silent: true,
          params: {
            dui: d.dui,
            preflight: 1
          }
        })
          .catch(function() {
            return { ok: false, dui: myDui, reason: "Error desconocido" };
          })
          .then(async function(r) {
            d.datosPreflight = r;
          });
      });
    },
    async storeVotante() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      // recopilar datos del modelo

      const d = this as any;

      const promise: Promise<any> = (function() {
        if (!d.dui.match("^[0-9]{8}-[0-9]$"))
          return Promise.resolve({ ok: false, reason: "DUI incorrecto" });
        if (!d.telefono.match("^[2678][0-9]{3}-[0-9]{4}$"))
          return Promise.resolve({
            ok: false,
            reason: "Teléfono de contacto incorrecto"
          });
        if (
          d.telefono2 != "" &&
          !d.telefono2.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 2 incorrecto"
          });
        if (
          d.telefono3 != "" &&
          !d.telefono3.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 3 incorrecto"
          });
        if (d.email != "" && !d.email.match("^[^@]+@[^.]+\\.[^.]+$"))
          return Promise.resolve({ ok: false, reason: "Email incorrecto" });

        return cyanRequest("addVoter", {
          isPost: true,
          isModal: "Registrando votante...",
          needsAuth: true,
          silent: true,
          params: {
            dui: d.dui,
            telefono: d.telefono,
            telefono2: d.telefono2,
            telefono3: d.telefono3,
            email: d.email
          }
        });
      })();

      promise
        .catch(function() {
          return { ok: false, reason: "Error desconocido" };
        })
        .then(async function(r) {
          let toastMessage: string;

          if (r.ok) {
            toastMessage =
              "Votante " +
              r.votante.dui +
              " (" +
              r.votante.nombre +
              ") registrado con éxito";
            store.commit("addVotante", r.votante);
            d.dui = "";
            d.telefono = "";
            d.telefono2 = "";
            d.telefono3 = "";
            d.email = "";
            d.datosPreflight = {};
          } else {
            toastMessage = r.reason;
          }

          if (r.ok) {
            const toast = await toastController.create({
              header: "Guardado con éxito",
              message: toastMessage,
              position: "bottom",
              buttons: [{ text: "OK", role: "cancel" }],
              duration: 3000
            });
            toast.present();
          } else {
            const alert = await alertController.create({
              header: "Error",
              message: toastMessage,
              buttons: ["OK"]
            });
            alert.present();
          }
        });
    }
  }
});
