<template>
  <!-- eslint-disable vue/no-deprecated-slot-attribute -->

  <ion-page><cyan-page-wrapper title="Carga Electoral" backRoute="/home" nav1="CARGA ELECTORAL" :burst="true">
    <user-data class="cabeceraUsuario mobileShowFlex" />

    <hr />

    <div class="votersContainer">
      <router-link to="/voterList" class="miLista">
        <img src="assets/images/Icono-Libreta-ConLinea.svg" />
        <div class="etiquetaMiLista">
          MI LISTA DE CONTACTOS
          <span v-if="listaVotantes.length">&nbsp;({{ listaVotantes.length }})</span>
        </div>
      </router-link>
      <div class="nuevoVotante">
        <div class="banner bold nivel1">AGREGAR VOTANTE</div>
        <div class="inputWrapper paraDUI">
          <div class="caption">NÚMERO DE DUI:</div>
          <div class="input">
            <ion-input
              type="tel"
              class="__voters-DUI"
              v-model="dui"
              placeholder="12345678-9"
              @ionBlur="preflightVotante()"
              @focusout="preflightVotante()"
            ></ion-input>
            <div class="validationFailed">
              <ion-icon :icon="warningOutline"></ion-icon>
            </div>
          </div>
        </div>
        <div :class="['duiLookup', estadoPreflightIcono]" v-if="estadoPreflight !== 'none'">
          <div class="duiLookupIcon">
            <ion-spinner v-if="estadoPreflight == 'running'"></ion-spinner>
            <ion-icon :icon="iconoPreflight" v-if="estadoPreflight == 'error' || estadoPreflight == 'ok'"></ion-icon>
          </div>
          <div class="reason" v-if="estadoPreflight == 'running'">Comprobando...</div>
          <div class="reason" v-if="estadoPreflight == 'error'">{{ datosPreflight.reason }}</div>
          <div class="dato" v-if="datosPreflight.nombre">
            <span>Nombre:</span>
            {{ datosPreflight.nombre }}
          </div>
          <div class="dato" v-if="datosPreflight.departamento">
            <span>Departamento:</span>
            {{ datosPreflight.departamento }}
          </div>
          <div class="dato" v-if="datosPreflight.municipio">
            <span>Distrito:</span>
            {{ datosPreflight.municipio }}
          </div>
        </div>
        <div class="inputWrapper paraTelefono">
          <div class="caption">TELÉFONO DE CONTACTO:</div>
          <div class="input">
            <ion-input
              type="tel"
              class="__voters-telefono"
              v-model="telefono"
              placeholder="6789-1234"
            ></ion-input>
            <div class="validationFailed">
              <ion-icon :icon="warningOutline"></ion-icon>
            </div>
          </div>
        </div>
        <div class="inputWrapper paraTelefono">
          <div class="caption">
            TELÉFONO 2
            <span>(OPCIONAL)</span>:
          </div>
          <div class="input">
            <ion-input
              type="tel"
              class="__voters-telefono2"
              v-model="telefono2"
              placeholder="6789-1234"
            ></ion-input>
            <div class="validationFailed">
              <ion-icon :icon="warningOutline"></ion-icon>
            </div>
          </div>
        </div>
        <div class="inputWrapper paraTelefono">
          <div class="caption">
            TELÉFONO 3
            <span>(OPCIONAL)</span>:
          </div>
          <div class="input">
            <ion-input
              type="tel"
              class="__voters-telefono3"
              v-model="telefono3"
              placeholder="6789-1234"
            ></ion-input>
            <div class="validationFailed">
              <ion-icon :icon="warningOutline"></ion-icon>
            </div>
          </div>
        </div>
        <div class="inputWrapper paraEmail">
          <div class="caption">
            CORREO
            <span>(OPCIONAL)</span>:
          </div>
          <div class="input">
            <ion-input
              v-model="email"
              type="email"
              class="__voters-email"
              placeholder="correo@gmail.com"
            ></ion-input>
            <div class="validationFailed">
              <ion-icon :icon="warningOutline"></ion-icon>
            </div>
          </div>
        </div>
        <div class="buttonContainer">
          <ion-button @click="storeVotante" expand="block" class="__voters-next" tabindex="0">
            <ion-icon :icon="personAdd" slot="end"></ion-icon>Guardar
          </ion-button>
        </div>
      </div>
    </div>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  router,
  store,
  defineComponent
} from "@/components/CyanPageWrapper.vue";
import cyanRequest from "@/modules/cyanRequest";
import UserData from "@/components/UserData.vue";
import {
  IonButton,
  IonInput,
  IonIcon,
  IonPage,
  IonSpinner,
  toastController,
  alertController
} from "@ionic/vue";
import {
  personAdd,
  warningOutline,
  checkmarkCircleOutline
} from "ionicons/icons";
import ValidatorSet, {
  validateDUI,
  validatePhone,
  validateEmail
} from "@/modules/cyanValidator";
import buttonTrigger from "@/modules/cyanButtonTrigger";
import unreact from "@/modules/unreact";

export default defineComponent({
  name: "Voters",
  components: {
    IonButton,
    IonInput,
    IonIcon,
    IonSpinner,
    IonPage,
    UserData,
    CyanPageWrapper
  },
  setup() {
    return {
      personAdd,
      warningOutline,
      checkmarkCircleOutline
    };
  },
  data() {
    return {
      dui: "",
      email: "",
      telefono: "",
      telefono2: "",
      telefono3: "",
      validator: null as any,
      datosPreflight: {}
    };
  },
  mounted() {
    this.validator = new ValidatorSet([
      {
        validator: validateDUI,
        element: ".__voters-DUI",
        nextElement: ".__voters-telefono"
      },
      {
        validator: validatePhone,
        element: ".__voters-telefono",
        nextElement: ".__voters-telefono2",
        fieldName: "Teléfono de contacto"
      },
      {
        validator: validatePhone,
        element: ".__voters-telefono2",
        optional: true,
        nextElement: ".__voters-telefono3",
        fieldName: "Teléfono 2"
      },
      {
        validator: validatePhone,
        element: ".__voters-telefono3",
        optional: true,
        nextElement: ".__voters-email",
        fieldName: "Teléfono 3"
      },
      {
        validator: validateEmail,
        element: ".__voters-email",
        nextElement: ".__voters-next",
        optional: true
      }
    ]);

    buttonTrigger(".__voters-next", this.storeVotante);
  },
  updated() {
    if (this.validator) this.validator.bind();
    buttonTrigger(".__voters-next", this.storeVotante);
  },

  computed: {
    listaVotantes() {
      return store.getters.listaVotantes;
    },
    estadoPreflight() {
      const p = this.datosPreflight as any;
      if (typeof p.ok === "undefined") return "none";
      if (p.running) return "running";
      return p.ok ? "ok" : "error";
    },
    votersButtonLabel() {
      return (
        "LISTA DE VOTANTES REGISTRADOS" +
        (store.getters.listaVotantes.length
          ? " (" + store.getters.listaVotantes.length + ")"
          : "")
      );
    },

    router() {
      return router;
    },

    iconoPreflight() {
      if(this.estadoPreflight === 'ok') return checkmarkCircleOutline;
      if((this.datosPreflight as any).checkVerde) return checkmarkCircleOutline;
      return warningOutline;
    },
    estadoPreflightIcono() {
      if((this.datosPreflight as any).checkVerde && this.estadoPreflight === 'error') {
        return 'ok';
      }
      return (this as any).estadoPreflight || 'none';
    }
  },
  methods: {
    async preflightVotante() {
      const d = this as any;
      // esperar para que la máscara haga lo suyo
      await new Promise(function(resolve) {
        setTimeout(resolve, 50);
      }).then(function() {
        if (!d.dui.match("^[0-9]{8}-[0-9]$")) {
          d.datosPreflight = {};
          return;
        }

        if (d.dui == d.datosPreflight.dui) return;

        const myDui = unreact(d.dui);

        d.datosPreflight = { ok: false, running: true, dui: myDui };

        cyanRequest("addVoter", {
          isPost: true,
          needsAuth: true,
          silent: true,
          params: {
            dui: d.dui,
            preflight: 1
          }
        })
          .catch(function() {
            return { ok: false, dui: myDui, reason: "Error desconocido" };
          })
          .then(async function(r) {
            d.datosPreflight = r;
          });
      });
    },
    async storeVotante() {
      if (this.validator) {
        if (!(await this.validator.checkWithAlert())) return;
      }

      // recopilar datos del modelo

      const d = this as any;

      const promise: Promise<any> = (function() {
        if (!d.dui.match("^[0-9]{8}-[0-9]$"))
          return Promise.resolve({ ok: false, reason: "DUI incorrecto" });
        if (!d.telefono.match("^[2678][0-9]{3}-[0-9]{4}$"))
          return Promise.resolve({
            ok: false,
            reason: "Teléfono de contacto incorrecto"
          });
        if (
          d.telefono2 != "" &&
          !d.telefono2.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 2 incorrecto"
          });
        if (
          d.telefono3 != "" &&
          !d.telefono3.match("^[2678][0-9]{3}-[0-9]{4}$")
        )
          return Promise.resolve({
            ok: false,
            reason: "Teléfono 3 incorrecto"
          });
        if (d.email != "" && !d.email.match("^[^@]+@[^.]+\\.[^.]+$"))
          return Promise.resolve({ ok: false, reason: "Email incorrecto" });

        return cyanRequest("addVoter", {
          isPost: true,
          isModal: "Registrando votante...",
          needsAuth: true,
          silent: true,
          params: {
            dui: d.dui,
            telefono: d.telefono,
            telefono2: d.telefono2,
            telefono3: d.telefono3,
            email: d.email
          }
        });
      })();

      promise
        .catch(function() {
          return { ok: false, reason: "Error desconocido" };
        })
        .then(async function(r) {
          let toastMessage: string;

          if (r.ok) {
            toastMessage =
              "Votante " +
              r.votante.dui +
              " (" +
              r.votante.nombre +
              ") registrado con éxito";
            store.commit("addVotante", r.votante);
            d.dui = "";
            d.telefono = "";
            d.telefono2 = "";
            d.telefono3 = "";
            d.email = "";
            d.datosPreflight = {};
          } else {
            toastMessage = r.reason;
          }

          if (r.ok) {
            const toast = await toastController.create({
              header: "Guardado con éxito",
              message: toastMessage,
              position: "bottom",
              buttons: [{ text: "OK", role: "cancel" }],
              duration: 3000
            });
            toast.present();
          } else {
            const alert = await alertController.create({
              header: "Error",
              message: toastMessage,
              buttons: ["OK"]
            });
            alert.present();
          }
        });
    }
  }
});
</script>

<style scoped>
.votersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.miLista,
.nuevoVotante {
  flex: 1 1 auto;
  padding: 10px 0;
}

.nuevoVotante {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.miLista {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-right: 10px;
}

.miLista img {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  margin-right: 15px;
}

.etiquetaMiLista {
  font-weight: bold;
  color: var(--ion-color-primary);
  font-size: 120%;
  flex: 1 1 auto;
}

.etiquetaMiLista span {
  color: var(--ion-color-secondary);
}

.inputWrapper {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.inputWrapper .caption {
  flex: 0 0 auto;
  color: var(--ion-color-primary);
  font-weight: 500;
  padding-right: 5px;
}

.inputWrapper .caption span {
  color: var(--ion-color-secondary);
  font-weight: normal;
}

.inputWrapper .input {
  flex: 1 1 95px;
}

.inputWrapper.paraDUI .input {
  flex: 1 1 105px;
}

.inputWrapper.paraEmail .input {
  flex: 1 1 170px;
}

.validationFailed {
  color: var(--ion-color-danger);
  position: absolute;
  right: 5px;
  bottom: -4px;
  font-size: 22px;
  z-index: 10;
}

ion-input {
  --background: white;
  --color: var(--ion-color-dark);
  border-radius: 7px;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 8px;
  --padding-end: 8px;
  border: 1px solid var(--ion-color-secondary);
  font-size: 90%;
  align-self: end;
}

ion-button {
  --color: var(--ion-color-tertiary);
  --background: var(--ion-color-primary);
}

hr {
  border-top: 3px solid var(--ion-color-primary);
  height: 0;
  margin: 5px;
}

.duiLookup {
  background: white;
  color: var(--ion-color-dark);
  border-radius: 7px;
  padding: 8px 8px 8px 35px;
  margin: 0 0 5px;
  position: relative;
  border: 1px solid var(--ion-color-secondary);
  font-size: 90%;
}

.duiLookupIcon {
  position: absolute;
  top: 6px;
  left: 8px;
  font-size: 22px;
}

.error .duiLookupIcon ion-icon {
  color: var(--ion-color-danger);
}

.ok .duiLookupIcon ion-icon {
  color: var(--ion-color-success);
}

.duiLookup ion-spinner {
  --color: var(--ion-color-primary);
  height: 22px;
  width: 22px;
}

.duiLookup .dato,
.duiLookup .reason {
  padding-left: 1em;
  text-indent: -1em;
}

.duiLookup.error .reason {
  color: var(--ion-color-danger);
}

.duiLookup .dato span {
  font-weight: bold;
  color: var(--ion-color-primary);
}

@media (min-width: 768px) and (min-height: 500px) {
  .votersContainer {
    flex-direction: row;
  }
  .miLista,
  .nuevoVotante {
    flex: 1 1 200px;
  }

  .miLista {
    padding: 0 10px 0 0;
  }

  .nuevoVotante {
    padding: 0;
  }
}

@media (max-height: 400px) {
  .cabeceraUsuario, hr {
    display: none;
  }
}
</style>